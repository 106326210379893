import React, { Component } from "react";
import '../style/qr.scss';
import QRCode from "react-qr-code";
import { Helmet } from "react-helmet";
import { metaTitle } from '../config';
import Header from "./Header";
import { 
  Container
} from "react-bootstrap";

class QRGenerator extends Component {

  componentDidMount() {
    
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{ metaTitle('Cart') }</title>
        </Helmet>
        <Header />
        <Container>
          <div className="qr-div">
            <h3>Your code is ready!!</h3>
            <div className="split2">
              <label>Token No: 4555</label>
              <label>Time: 12:00 PM</label>
            </div>
            <QRCode value="12121222" />

            <button className="download">Download Code</button>
            <button className="done">Done</button>
          </div>
        </Container>
      </React.Fragment>
      );
  }
}
export default QRGenerator;