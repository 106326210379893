import React, { Component } from "react";
import axios from 'axios';
import '../style/bill.scss';
import Header from "./Header";
import { 
    Container
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Redirect } from 'react-router-dom';
import { api_base, user_key, metaTitle } from '../config';
import Loading from "./Loading";

class Bill extends Component {

  state = {
    loading: false
  }

  componentDidMount() {
    
  }

  render() {
    if(false === true){
      return <Redirect to='/login' />
    }
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{ metaTitle('My Bill') }</title>
        </Helmet>
        <Header />
        <Container className="bill-container">
          <div className="form-box">
            <h2 className="head-container">My Last Bills</h2>
            <div className="table-responsive">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Order ID</th>
                    <th scope="col">Date & Time</th>
                    <th scope="col">Amount</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="bold">ORD10001</td>
                    <td>12/12/2022 12:22AM</td>
                    <td className="bold">150000</td>
                    <td><button className="detail-button">View</button></td>
                  </tr>
                  <tr>
                    <td className="bold">ORD10001</td>
                    <td>12/12/2022 12:22AM</td>
                    <td className="bold">150000</td>
                    <td><button className="detail-button">View</button></td>
                  </tr>
                  <tr>
                    <td className="bold">ORD10001</td>
                    <td>12/12/2022 12:22AM</td>
                    <td className="bold">150000</td>
                    <td><button className="detail-button">View</button></td>
                  </tr>
                  <tr>
                    <td className="bold">ORD10001</td>
                    <td>12/12/2022 12:22AM</td>
                    <td className="bold">150000</td>
                    <td><button className="detail-button">View</button></td>
                  </tr>
                  <tr>
                    <td className="bold">ORD10001</td>
                    <td>12/12/2022 12:22AM</td>
                    <td className="bold">150000</td>
                    <td><button className="detail-button">View</button></td>
                  </tr>
                  <tr>
                    <td className="bold">ORD10001</td>
                    <td>12/12/2022 12:22AM</td>
                    <td className="bold">150000</td>
                    <td><button className="detail-button">View</button></td>
                  </tr>
                  <tr>
                    <td className="bold">ORD10001</td>
                    <td>12/12/2022 12:22AM</td>
                    <td className="bold">150000</td>
                    <td><button className="detail-button">View</button></td>
                  </tr>
                  <tr>
                    <td className="bold">ORD10001</td>
                    <td>12/12/2022 12:22AM</td>
                    <td className="bold">150000</td>
                    <td><button className="detail-button">View</button></td>
                  </tr>
                </tbody>
              </table>
            </div>            
          </div>
        </Container>
        <Loading loading={this.state.loading}/>
      </React.Fragment>
    );
  }
}
export default Bill;
