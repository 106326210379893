import React, { Component } from "react";
import logo from '../img/logo1.png';
import '../style/account.scss';
import axios from 'axios';
import Header from "./Header";
import { 
    Container
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Redirect } from 'react-router-dom';
import { api_base, user_key, metaTitle } from '../config';
import Loading from "./Loading";

class Login extends Component {

  state = {
    loading: false,
    name: '',
    email: '',
    mobile: '',
    address: '',
    otp: '',
    otp_form: true,
    register_form: false,
    login_form: false,
  }

  componentDidMount() {
    
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      otp_form: false,
      register_form: true,
      login_form: false
    });
  };

  handleRegisterSubmit = (event) => {
    event.preventDefault();
    this.setState({
      otp_form: false,
      register_form: false,
      login_form: true
    });
  };

  handleLoginSubmit = (event) => {
    event.preventDefault();
  
  };

  inputChange = (event) =>{
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  render() {
    if(false === true){
      return <Redirect to='/cart' />
    }
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{ metaTitle('Sign In') }</title>
        </Helmet>
        <Header />
        <Container className="my-account">
        <form method="post" className={"form-box " + (this.state.otp_form ? "show" : "hide") } onSubmit={this.handleSubmit}>
            <div className="user-logo">
              <img src={logo} alt="logo" className="logo"/>
            </div>
            <ul className="ul-form">
              <li>
                <label>Email Address</label>
                <input type="email" name="email" placeholder="Enter Your Email Address" value={this.state.email} onChange={this.inputChange} required/>
              </li>
              <li>
                <button className="save">Get OTP</button>
              </li>
            </ul>
          </form>

          <form method="post" className={"form-box " + (this.state.register_form ? "show" : "hide") } onSubmit={this.handleRegisterSubmit}>
            <div className="user-logo">
              <img src={logo} alt="logo" className="logo"/>
            </div>
            <ul className="ul-form">
              <li>
                <label>Full Name</label>
                <input type="text" name="name" placeholder="Enter Your Full Name" value={this.state.name} onChange={this.inputChange} required/>
              </li>
              <li>
                <label>Email Address</label>
                <input type="email" name="email" placeholder="Enter Your Email Address" value={this.state.email} onChange={this.inputChange} required/>
              </li>
              <li>
                <label>Mobile Number</label>
                <input type="tel" name="mobile" placeholder="Enter Your Mobile Number" value={this.state.mobile} onChange={this.inputChange} required/>
              </li>
              <li>
                <label>Address</label>
                <textarea name="address" placeholder="Enter Your Address" required value={this.state.address} onChange={this.inputChange}></textarea>
              </li>
              <li>
                <label>OTP</label>
                <input type="text" name="otp" placeholder="Enter Your OTP" value={this.state.otp} onChange={this.inputChange} required/>
              </li>
              <li>
                <button className="save">Create Account</button>
              </li>
            </ul>
          </form>

          <form method="post" className={"form-box " + (this.state.login_form ? "show" : "hide") } onSubmit={this.handleLoginSubmit}>
            <div className="user-logo">
              <img src={logo} alt="logo" className="logo"/>
            </div>
            <ul className="ul-form">
              <li>
                <label>Email Address</label>
                <input type="email" name="email" placeholder="Enter Your Email Address" value={this.state.email} onChange={this.inputChange} required/>
              </li>
              <li>
                <label>OTP</label>
                <input type="text" name="otp" placeholder="Enter Your OTP" value={this.state.otp} onChange={this.inputChange} required/>
              </li>
              <li>
                <button className="save">Sign In</button>
              </li>
            </ul>
          </form>
        </Container>
        <Loading loading={this.state.loading}/>
      </React.Fragment>
    );
  }
}
export default Login;
