import React, { Component } from "react";
import axios from 'axios';
import '../style/account.scss';
import Header from "./Header";
import { 
    Container
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Redirect } from 'react-router-dom';
import { api_base, user_key, metaTitle } from '../config';
import Loading from "./Loading";
import account from '../img/home.png';

class Account extends Component {

  state = {
    loading: false
  }

  componentDidMount() {
    
  }

  handleSubmit = (event) => {
    event.preventDefault();
  
  };

  render() {
    if(false === true){
      return <Redirect to='/login' />
    }
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{ metaTitle('My Account') }</title>
        </Helmet>
        <Header />
        <Container className="my-account">
          <form method="post" className="form-box" onSubmit={this.handleSubmit}>
            <div className="user-logo">
              <img src={account} alt="account"/>
            </div>
            <ul className="ul-form">
              <li>
                <label>Full Name</label>
                <input type="text" name="name" placeholder="Enter Your Full Name" value="Sreejith" required/>
              </li>
              <li>
                <label>Mobile Number</label>
                <input type="text" name="mobile" placeholder="Enter Your Mobile Number" value="9847012345" required/>
              </li>
              <li>
                <label>Email Address</label>
                <input type="email" name="email" placeholder="Enter Your Mobile Number" value="admin@admin.com" required/>
              </li>
              <li>
                <label>Address</label>
                <textarea name="address" placeholder="Enter Your Address"></textarea>
              </li>
              <li>
                <button className="save">Update Profile</button>
              </li>
            </ul>
          </form>
          
        </Container>
        <Loading loading={this.state.loading}/>
      </React.Fragment>
    );
  }
}
export default Account;
