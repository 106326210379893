import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import Home from "./components/Home";
import Order from "./components/Order";
import Cart from "./components/Cart";
import QRGenerator from "./components/QRGenerator";
import Account from "./components/Account";
import Login from "./components/Login";
import Bill from "./components/Bill";
import Page404 from "./components/Page404";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route path="/" exact component={Home} />
          {/* <Route path="/table/:ID" component={Home} /> */}
          <Route path="/order" component={Order} />
          <Route path="/cart" component={Cart} />
          <Route path="/qr" component={QRGenerator} />
          <Route path="/account" component={Account} />
          <Route path="/login" component={Login} />
          <Route path="/bill" component={Bill} />
          <Route component={Page404} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
