import React, { Component } from "react";
import '../style/item.scss';
import { currency } from '../config';

class ItemDetails extends Component {

  round = (value) => {
    let num = parseFloat(value).toFixed(2);
    return num;
  }

  addToCart = (id) => {
    this.props.addToCart(id);
  };

  render() {
    let item = this.props.popup_data;
    return (
      <div className={(this.props.popup_item) ? `item-detail-popup` : `item-detail-popup hide`}>
        <div className="detail-item-row">
          <div className="category-div">
            <img
                src={item.cat_img}
                alt={item.cat_name}
              />
              <h5 >{item.cat_name}</h5>
              <div className="clearfix"></div>
          </div>
          <div className="items">
              <img
                src={item.image}
                alt={item.title}
              />
              <h5>{item.product_id} : {item.title}</h5>
              <h6>{this.round(item.price)} {currency}</h6>
              <p>{item.short_description}</p>
              <p>{item.description}</p>

              <div className="btn-div">
                <button className="add-to-card" onClick={() => this.props.addToCart(item.id)}>Add To Cart</button>
                <button className="close-to-item" onClick={this.props.itemPopClose}>Close</button>
              </div>
            </div>
        </div>
      </div>
    );
  }
}
export default ItemDetails;
