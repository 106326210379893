import React, { Component } from "react";
import Items from "./CartItems";
import '../style/order.scss';
import { 
    Container
} from "react-bootstrap";
import Header from "./Header";
import QRGenerator from "./QRGenerator";
import Payment from "./Payment";
import { Helmet } from "react-helmet";
import { Redirect } from 'react-router-dom';
import { Row, Col } from "react-bootstrap";
import axios from 'axios';
import { metaTitle, isOnlyMenu, user_key, api_base } from '../config';

class Cart extends Component {

  state = {
    null_item: false,
    cart_item: [],
    table: 'home',
    items: [],
    totalAmount: 0,
    position: '',
    name: '',
    mobile: '',
    email: '',
    order_redirect: false,
    fail_name: false,
    fail_mobile: false,
    fail_email: false,
    qr_show: false,
    payment_show: false
  }

  componentDidMount(){
    let table = sessionStorage.getItem("table");
    if(!table){
      table = 'home';
    }
    const cart = sessionStorage.getItem('cart');
    let cart_item = [];
    if (cart) {
      cart_item = JSON.parse(cart);
    }else{
      this.setState({ null_item: true });
    }

    this.setState({
      cart_item: cart_item,
      table: table,
    });
    this.setGeo();
  }

  setGeo(){
    navigator.geolocation.getCurrentPosition(function(position) {
        sessionStorage.setItem('position', position.coords.latitude+','+position.coords.longitude);
    });
  }

  addItems = (items) => {
    this.setState({
      items: items
    });
  }

  increment = (id) =>{
    let cart = this.state.cart_item;
    cart.forEach((item, key) => {
      if(item.id === id){
        let i_count = item.count+1;
        cart[key] = {
          id: id,
          count: i_count,
          note: item.note
        }
        return true;
      }
    });
    sessionStorage.setItem('cart', JSON.stringify(cart));
    this.setState({
      cart_item: cart
    });
  }

  decrement = (id) =>{
    let cart = this.state.cart_item;
    cart.forEach((item, key) => {
      if(item.id === id){
        let i_count = item.count-1;
        if(i_count <= 0){
          i_count = 0;
        }
        cart[key] = {
          id: id,
          count: i_count,
          note: item.note
        }
        return true;
      }
    });
    sessionStorage.setItem('cart', JSON.stringify(cart));
    this.setState({
      cart_item: cart
    });
  }

  addNote = (event) =>{
    let id = event.target.name;
    let value  = event.target.value;
    let cart = this.state.cart_item;
    cart.forEach((item, key) => {
      if(item.id === id){
        cart[key] = {
          id: id,
          count: item.count,
          note: value
        }
        return true;
      }
    });
    sessionStorage.setItem('cart', JSON.stringify(cart));
    this.setState({
      cart_item: cart
    });
  }

  round = (value) => {
    let num = parseFloat(value).toFixed(2);
    return num;
  }

  toPayment = (event) => {
    event.preventDefault();
    let product = this.state.items;
    let cart = this.state.cart_item;
    let position = sessionStorage.getItem("position");
    let map = 'https://maps.google.com/maps?daddr='+position;
    if(!isOnlyMenu){
      let flag = true;
      if(this.state.name === ""){
        this.setState({ fail_name: true });
        flag = false;
      } else{
        this.setState({ fail_name: false });
      }
      if(this.state.mobile === ""){
        this.setState({ fail_mobile: true });
        flag = false;
      } else{
        this.setState({ fail_mobile: false });
      }

      if(flag === true){
        const options = {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "x-api-key": user_key
          }
        };
        axios.post(api_base+'/cart', {
            json: JSON.stringify(cart),
            name: this.state.name,
            mobile: this.state.mobile
          },options
        ).then(response => {
          const result = response.data;
          let totalAmount = sessionStorage.getItem("totalAmount");
          if(result.status === true){
            this.setState({ 
              payment_show: true,
              totalAmount: totalAmount * 100
            });
          }
        }).catch((response) => {
          this.setState({ items: [], loading: false });
        });
      }
    }
  }

  qrHide = () => {
    this.setState({
      qr_show: false
     });
    this.props.history.push('/bill');
  }
  paymentHide = () => {
    this.setState({
      payment_show: false,
      qr_show: true
     });
  }

  deleteItem = (items) => {
    let cart = this.state.cart_item;
    cart.forEach((item, key) => {
      if(item.id === items){
        cart.splice(key, 1);
        return true;
      }
    });
    sessionStorage.setItem('cart', JSON.stringify(cart));
    this.setState({
      cart_item: cart
    });
  }

  clear = () => {
    sessionStorage.removeItem('cart');
    this.setState({
      null_item: true
     });
  }

  back = () => {
    this.setState({
      order_redirect: true
     });
    this.props.history.push('/');
  }

  tableChange = (table) => {
    let address_text = '';
    if(table === 'home'){
      address_text = 'Address and Landmark:';
    }else if(table === 'take'){
      address_text = 'More Info:';
    }else if(table !== ''){
      address_text = 'Table Order';
    }else{
      address_text = 'Address and Landmark:';
    }

    this.setState({
      table: table,
      address_text: address_text,
      address: ''
    });
  }

  inputChange = (event) =>{
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  render() {
    if(this.state.null_item === true || this.state.order_redirect === true){
      return <Redirect to='/order' />
    }
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{ metaTitle('Cart') }</title>
        </Helmet>
        <Header />
        <Container>
          <Items back={this.back} clear={this.clear} increment={this.increment} decrement={this.decrement} deleteItem={this.deleteItem} addNote={this.addNote} cart_item={this.state.cart_item} addItems={this.addItems} round={this.round}/>
        </Container>
        <form method="post" className="form-box" onSubmit={this.toPayment}>
          <Container className="place-details">
            <Row>
                <Col md={12}>
                <div className="sale-items order-clear-box">
                  <div className="cart-clear-div address-tab padding-top-bottom-5">
                    <label className={(this.state.name !== '') ? 'visibility-visible' : 'visibility-hidden'}>Full Name:</label>
                    <input type="text" name="name" placeholder="Enter Your Full Name" value={this.state.name} onChange={this.inputChange} required/>
                    <span className={(this.state.fail_name) ? 'error-span visibility-visible' : 'visibility-hidden'}>Enter Your Full Name</span>
                  </div>
                </div>
              </Col>
              <Col md={12}>
                <div className="sale-items order-clear-box">
                  <div className="cart-clear-div address-tab padding-top-bottom-5">
                    <label className={(this.state.mobile !== '') ? 'visibility-visible' : 'visibility-hidden'}>Mobile Number:</label>
                    <input type="text" name="mobile" placeholder="Enter Your Mobile Number" value={this.state.mobile} onChange={this.inputChange} required/>
                    <span className={(this.state.fail_mobile) ? 'error-span visibility-visible' : 'visibility-hidden'}>Enter Mobile Number</span>
                  </div>
                </div>
              </Col>
              <Col md={12}>
                <div className="sale-items order-clear-box">
                  <div className="cart-clear-div address-tab padding-top-bottom-5">
                    <label className={(this.state.email !== '') ? 'visibility-visible' : 'visibility-hidden'}>Email Address:</label>
                    <input type="text" name="email" placeholder="Enter Your Email Address" value={this.state.email} onChange={this.inputChange}/>
                    <span className={(this.state.fail_email) ? 'error-span visibility-visible' : 'visibility-hidden'}>Enter Email Address</span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
            <Container>
              <div className="whatsapp-share mb-95">
                <button className="whatsapp-share-link">
                  <strong>
                    <i className="logo-pay"></i>
                    <label>Proceed to payment</label>
                  </strong>
                </button>
              </div>
            </Container>
        </form>

        {(this.state.payment_show) ? (
          <Payment paymentHide={this.paymentHide} totalAmount={ this.state.totalAmount }/>
        ): '' }

      </React.Fragment>
    );
  }
}
export default Cart;
