import React from 'react';
import logo1 from '../img/logo1.png';
import logo2 from '../img/logo2.png';
import '../style/header.scss';
import { 
  Container,
  Row,
  Col
} from 'react-bootstrap';
import { app_title, app_for, mobile } from '../config';

function Header(props) {
  return (
    <React.Fragment>
    <header>
      <Container className="top-head">
        <Row className="justify-content-center top-head-row">
          <div className='logo-div'>
            <img src={logo1} alt="logo" className="top-head-logo" />
            <img src={logo2} alt="logo" className="top-head-logo" />
          </div>
        </Row>
          <h2>{ app_title } <span>{ app_for }</span></h2>
      </Container>
    </header>
    <div className="header-mask"></div>
    <div className="clearfix"></div>
    </React.Fragment>
  );
}

export default Header;