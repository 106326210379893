import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import '../style/item.scss';
import { currency, isOnlyMenu } from '../config';

class Items extends Component {
  addToCart = (id) => {
    this.props.addToCart(id);
  };

  round = (value) => {
    let num = parseFloat(value).toFixed(2);
    return num;
  }

  viewItem = (id) => {
    this.props.viewItem(id);
  }

  render() {
    let product = Object.values(this.props.items);
    return (
      <Row className="item-row">
        {product.map((item, key) => {
          return (
            <Col md={6} sm={12} className="sale-items" key={key}>
                <div className="items">
                  <img
                    src={item.product_image}
                    alt={item.title}
                    className="category-logo"
                    onClick={() => this.viewItem(item.id)}
                  />
                  <div className="items-details">
                    <div className="items-box">
                      <h5>{item.name} { (item.product_id) ? `(${item.product_id})` : '' }</h5>
                      <label>{this.round(item.price)} {currency}</label>
                      <button className="view-to-item" onClick={() => this.viewItem(item.id)} >View More</button>
                      <button className={(isOnlyMenu) ? 'hide' : 'add-to-cart'}  onClick={() => this.addToCart(item.id)} >Add to cart</button>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </div>
            </Col>
          );
        })}
      </Row>
    );
  }
}
export default Items;
