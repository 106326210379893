import React, { Component } from "react";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import { api_base, user_key, currency } from '../config';
import '../style/item.scss';
import Loading from "./Loading";

class Items extends Component {
  state = {
    items: [],
    value: 'add',
    loading: false
  };

  componentDidMount() {
    const cart = sessionStorage.getItem("cart");
    let cart_item = [];
    if (cart) {
      cart_item = JSON.parse(cart);
    } else {
      this.setState({ null_item: true });
    }
    let id = "";
    cart_item.forEach((items) => {
      id += items.id + "-";
    });

    if (id !== "") {
      id = id.slice(0, -1);
    }

    this.setState({ loading: true });
    const options = {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "x-api-key": user_key
      }
    };
    axios
      .post(
        api_base + "/product/multi",
        {
          items: id,
        },
        options
      )
      .then((response) => {
        const datas = response.data;
        if (datas.status === true) {
          let it = Object.values(datas.data);
          this.setState({ items: it });
          this.props.addItems(it);
          this.setState({ loading: false });
        }
      })
      .catch((response) => {
        this.setState({ items: [], loading: false });
      });
  }

  deleteItem = (item, key) => {
    let items = this.state.items;
    items.splice(key, 1);
    this.setState({ items: items });
    this.props.deleteItem(item);
  }
  
  render() {
    let product = this.state.items;
    let i_count = 0;
    let i_total = 0;
    let i_note = '';
    let s_total = 0;
    let cart = this.props.cart_item;

    return (
      <Row>
        <Col md={12} className="sale-items">
          <div className="sale-items-final order-clear-box">
            <Row className="justify-content-between">
              <Col>
                <div className="cart-clear-div"><div className="cart-back" onClick={this.props.back}>Home</div></div>
              </Col>
              <Col>
                <div  className="cart-clear-div cart-clear-div-right">
                  <div className="cart-clear" onClick={this.props.clear}>Clear</div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        {
          product.map((item, key) => {
            cart.forEach((cart_item) => {
              if(item.id === cart_item.id){
                i_count = cart_item.count;
                i_note = cart_item.note;
                return true;
              }
            });
            i_total = (i_count*item.price);
            s_total += i_total;
            sessionStorage.setItem("totalAmount", s_total);    
            return (
              <Col md={12} className="sale-items" key={key}>
                <div className="sale-items-final">
                  <div className="cart-items">
                    <div className="first-box">
                      <img
                        src={item.product_image}
                        alt={item.name}
                        className="category-logo"
                      />
                      <label>
                        {this.props.round(i_total)}
                      </label>
                    </div>
                    <div className="second-box">
                      <h5>{item.name} { (item.product_id) ? `(${item.product_id})` : '' }</h5>
                      <label>{this.props.round(item.price)}x{i_count}</label>
                      <div className="remove-from-cart" onClick={() => this.deleteItem(item.id, key)}>Remove</div>
                    </div>
                    <div className="mult">
                        <button onClick={() => this.props.increment(item.id)} className="plus">+</button>
                        <input type="text" value={i_count} readOnly />
                        <button onClick={() => this.props.decrement(item.id)} className="minus">-</button>
                    </div>
                  </div>
                  <div className="sale-items-support">
                    <textarea
                      className=""
                      name={item.id}
                      placeholder="Note/Comments:"
                      value={i_note}
                      onChange={this.props.addNote}
                    />
                  </div>
                </div>
              </Col>
            );
          })
        }
        
        <Col md={12} className="sale-items">
          <div className="sale-items-final order-clear-box">
            <Row className="justify-content-between">
              <Col>
                <div className="cart-clear-div"><div className="dummy-btn">&nbsp;</div></div>
              </Col>
              <Col>
                <div  className="cart-total">
                  Total Amount:
                  <br />
                  <strong>{this.props.round(s_total)} {currency}</strong>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        
        <Loading loading={this.state.loading}/>
      </Row>
    );
  }
}
export default Items;
