import React, { useEffect, useState } from 'react';
import '../style/qr.scss';
import { Redirect } from 'react-router-dom';

const Payment = (props) => {
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const openRazorpay = async () => {
      const options = {
        key: 'rzp_test_6qdbcjrihZW5Lv',
        amount: props.totalAmount,
        currency: 'INR',
        name: 'Restaurant',
        description: 'Make My Order: ',
        image: 'https://food-admin.aptoinnovations.com/storage/images/product/1701318270.jpg',
        // order_id: "21342432432", // Order ID from your server
        handler: function (response) {
          console.log(response.razorpay_payment_id);
          setRedirect(true);
        },
        prefill: {
          name: 'John Doe',
          email: 'johndoe@example.com',
          contact: '9999999999'
        },
        notes: {
          address: 'Restaurant'
        },
        theme: {
          color: '#F37254'
        }
      };
  
      const rzp = new window.Razorpay(options);
      rzp.open();
    };

    openRazorpay();
  }, [props]);

  if (redirect) {
    return <Redirect to="/qr" />;
  }

  return (
    ''
  );
};

export default Payment;