import React, { Component } from "react";
import axios from 'axios';
import '../style/order.scss';
import Header from "./Header";
import CategoryMenu from "./CategoryMenu";
import Items from "./Items";
import ItemDetails from "./ItemDetails";
import { 
    Container
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Redirect } from 'react-router-dom';
import { api_base, user_key, metaTitle, isOnlyMenu } from '../config';
import Loading from "./Loading";
class Order extends Component {

  state = {
    items: [],
    category: 0,
    sentCart: false,
    total: 0,
    itemCart: [],
    searchInput: '',
    clsCart: 'order-cart-end',
    popup_item: false,
    popup_data: {
      id: null,
      cat_name: "demo",
      cat_img: null,
      title: "demo",
      price: 0,
      our_price: 0,
      short_description: "demo",
      description: "demo",
      image: null
    },
    loading: false
  }

  componentDidMount() {
    const cart = sessionStorage.getItem('cart');
    let cart_item = [];
    if (cart) {
      cart_item = JSON.parse(cart);
    }else{
      cart_item = [];
    }
    if(cart_item.length > 0){
      let total = 0;
      cart_item.forEach(items => {
        total+=items.count;
      });
      this.setState({ 
        itemCart: cart_item,
        total: total
      });
    }
  }

  getItems(id, key = ''){
    this.setState({ loading: true });
    const options = {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "x-api-key": user_key
      }
    };

    if(id === 'hot'){
      axios.get(api_base+'/product', 
          options
      ).then(response => {
            const datas = response.data;
            if(datas.status === true){
                this.setState({ 
                  items: datas.data,
                  loading: false
                });
            }
      }).catch((response) => {
        this.setState({ items: [], loading: false });
      });
    } else{
      axios.post(api_base+'/product', 
          {
            category: id,
            key: key
          },
          options
      ).then(response => {
            const datas = response.data;
            if(datas.status === true){
                this.setState({ 
                  items: datas.data,
                  loading: false
                });
            }
      }).catch((response) => {
        this.setState({ items: [], loading: false });
      });
    }
  }

  changeCategory = (id) => {
    this.setState({ category: id });
    let key = this.state.searchInput;
    this.getItems(id, key);
  }

  addToCart = (id) => {
    this.setState({ 
      clsCart: 'order-cart-start',
      popup_item: false
    });
    let itemCart = this.state.itemCart;
    // let total = itemCart.length;
    let total = 0;
    let index = null;
    itemCart.forEach(items => {
      total+=items.count;
      if(items.id === id){
        index = itemCart.indexOf(items);
        // return true;
      }
    });
    if(index === null){
      let add = {
        id: id,
        count: 1,
        note: ''
      }
      itemCart.push(add);
    }else{
      let count = itemCart[index].count+1;
      let add = {
        id: id,
        count: count,
        note: ''
      }
      itemCart[index] = add;
    }
    this.setState({ 
      itemCart: itemCart,
      total: total+1
    });

    setTimeout(() => this.setState({ clsCart: 'order-cart-end' }), 1000);
  }

  sentCart = () => {
    let itemCart = this.state.itemCart;
    sessionStorage.setItem('cart', JSON.stringify(itemCart));
    this.props.history.push('/cart');
    this.setState({ sentCart: true });
  }

  searchChange = (event) => {
    let key = event.target.value;
    this.setState({
      [event.target.name]: key
    });

    let id = this.state.category;
    this.getItems(id, key);
  }

  clearSearch = () => {
    let key = '';
    this.setState({
      'searchInput': key
    });

    let id = this.state.category;
    this.getItems(id, key);
  }

  itemPopClose = () => {
    this.setState({ popup_item: false });
  }

  viewItem = (id) => {
    this.setState({ loading: true });
    const options = {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "x-api-key": user_key
      }
    };
    axios.get(api_base+'/product/'+id,
        options
    ).then(response => {
          const datas = response.data;
          if(datas.status === true){
            let result = datas.data;
            this.setState({ 
              popup_data: {
                id: result.id,
                cat_name: result.category,
                cat_img: result.category_url,
                title: result.name,
                product_id: result.product_id,
                price: result.price,
                our_price: result.our_price,
                description: result.description,
                image: result.product_image,
              },
              popup_item: true,
              loading: false
            });
          }
    }).catch((response) => {
      this.setState({ popup_item: false, loading: false });
    });
  }

  render() {
    if(this.state.sentCart === true){
      return <Redirect to='/cart' />
    }
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{ metaTitle('Order') }</title>
        </Helmet>
        <Header />
        <Container>
          <div className="search-box">
            <button className="search-button"> </button>
            <input type="text" value={this.state.searchInput} name="searchInput" onChange={ this.searchChange } />
            { (this.state.searchInput !== '') ? <span className="cross" onClick={ this.clearSearch }>x</span> : '' }  
          </div>
          <CategoryMenu changeCategory={this.changeCategory} category={this.state.category}/>
          <Items items={this.state.items} addToCart={this.addToCart} viewItem={this.viewItem}/>
        </Container>
        <div className={`order-cart ${this.state.clsCart} ${(isOnlyMenu) ? 'hide' : ''}`} onClick={this.sentCart}>
          <label>{this.state.total}</label>
        </div>
        <button className={ `order-cart-button ${(this.state.total > 0) ? '' : 'hide'}` } onClick={this.sentCart}>View Cart</button>

        <ItemDetails popup_item={this.state.popup_item} popup_data={this.state.popup_data} itemPopClose={this.itemPopClose} addToCart={this.addToCart}/>
        <Loading loading={this.state.loading}/>
      </React.Fragment>
    );
  }
}
export default Order;
