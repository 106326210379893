// import { decode } from 'js-base64';
export const app_title = 'Restaurant';
export const app_for = '';
export const api_base = 'https://food-admin.aptoinnovations.com/api';
export const user_key = '';
export const currency = 'INR';
export const isOnlyMenu = false;

export const mobile = '918943266662';
export const metaTitle = (title = '') => {
    if(title !== ''){
        title = ' | '+title;
    }
    return "Restaurant "+title;
}