import React, {Component} from 'react';
import logo1 from '../img/logo1.png';
import logo2 from '../img/logo2.png';
import logo3 from '../img/logo3.png';
import '../style/home.scss';
import { 
    Container,
    Row,
    Col
} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import { app_title, app_for, metaTitle } from '../config';
import Loading from "./Loading";

class Home extends Component{

    state = {
        name: '',
        tel: '',
        selected: 'home',
        loading: false
    }

    componentDidMount() {
        const { match:{params} } = this.props;
        let table = this.state.selected;
        if(params.ID) {
            table = params.ID;
            this.setState({
                selected: table
            });
        }
        sessionStorage.setItem('table', table);
    }

    onHandleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{ metaTitle('Home') }</title>
                </Helmet>
                <section className='home-section'>
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={5} sm={4}>
                                <div className="home-fluid">
                                    <div className="home-fluid-inner">
                                        <img src={logo1} alt="logo" className="home-logo xx1" />
                                        <img src={logo3} alt="logo" className="home-logo" />
                                        <h2>Welcome to <br/><strong>{ app_title }</strong><br/>{ app_for }</h2>
                                        <Link className="home-link" to="/order">Order Now</Link>

                                        <div className="tables">
                                        {/* <a href={`tel:+${mobile}`} target="_blank" rel="noopener noreferrer">Call Us: +{mobile}</a> */}

                                        <img src={logo2} alt="logo" className="home-logo xx2" />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <Loading loading={this.state.loading}/>
            </React.Fragment>
        );
    }
}
export default Home;