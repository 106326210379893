import React from 'react';
import { 
  Container
} from "react-bootstrap";
import error404 from "../error404.png";
import { metaTitle } from '../config';
import { Helmet } from "react-helmet";

function Page404(){
    return (
      <React.Fragment>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{ metaTitle('404') }</title>
          </Helmet>
        <Container>
          <img className="error404" alt="error404" src={error404}/>
        </Container>
      </React.Fragment>
    );
}
export default Page404;