import React from 'react';
import '../style/footer.scss';
import load_img from '../img/load.gif';

function Loading(props) {
  return (
    <React.Fragment>
    <div className={(props.loading) ? `loader-div` : `loader-div hide-block`}>
      <img src={load_img} alt="loading..." />
    </div>
    </React.Fragment>
  );
}

export default Loading;